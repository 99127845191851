import React from 'react';
import { ScrollView, View, Text, Button } from '@aws-amplify/ui-react';

const BookList = ({ books, deleteBook, loadBook }) => {
  return (
    <ScrollView>
      {books.map((book, index) => (
        <View
          key={book.id || book.title}
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginVertical: 10,
            padding: 10,
            backgroundColor: '#f0f0f0',
            borderBottomWidth: index !== books.length - 1 ? 1 : 0,
            borderBottomColor: '#ccc',
          }}
        >
          <View style={{ flex: 1 }}>
            <Text style={{ fontWeight: 'bold' }}>{book.title}</Text>
            <Text style={{ marginTop: 5 }}>{book.summary}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Button onClick={() => deleteBook(book)}>Delete book</Button>
            <Button onClick={() => loadBook(book)}>Load book</Button>
          </View>
        </View>
      ))}
    </ScrollView>
  );
};

export default BookList;