import React, { useState, useEffect } from "react";
import { WithContext as ReactTags } from 'react-tag-input';
import { listBooks } from "./graphql/queries";
import BookList from "./Components/BookList";
import {
  createBook as createBookMutation,
  updateBook as updateBookMutation,
  deleteBook as deleteBookMutation,
} from "./graphql/mutations";
import { API } from "aws-amplify";
import {
  withAuthenticator,
  Button,
  Heading,
  View,
  Card,
} from "@aws-amplify/ui-react";
import "./App.css";
import "./ReactTags.css";
import "@aws-amplify/ui-react/styles.css";


const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function App({ signOut }) {
  const [books, setBooks] = useState([]);
  const [genres, setGenres] = useState([]);
  const [targetLength, setTargetLength] = useState(10000);
  const [themes, setThemes] = useState([]);
  const [styles, setStyles] = useState([]);
  const [creativity, setCreativity] = useState(50);
  const [summary, setSummary] = useState("");
  const [title, setTitle] = useState("");
  const [outline, setOutline] = useState("");
  const [storyBase, setStorybase] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    fetchBooks();
    handleInputChange();
  }, []);

  async function fetchBooks() {
    const apiData = await API.graphql({ 
      query: listBooks,
      authMode: 'AMAZON_COGNITO_USER_POOLS' 
    });
    const BooksFromAPI = apiData.data.listBooks.items;
    setBooks(BooksFromAPI);
  }

  const handleInputChange = () => {
    console.log("handleInputChange()")
    // Check if any values in the form are different from the book in the books list
    const book = books.find(book => book.title === title);
    if (book) {
      const dirty = (
        book.genres.some((genre, index) => genre !== genres[index]) ||
        book.themes.some((theme, index) => theme !== themes[index]) ||
        book.styles.some((style, index) => style !== styles[index]) ||
        book.targetLength !== targetLength ||
        book.creativity !== creativity ||
        book.summary !== summary ||
        book.outline !== outline ||
        book.content !== storyBase
      );
      setIsDirty(dirty);
    } else {
      setIsDirty(true);
    }
  }

  async function createBook(event) {
    event.preventDefault();
    // const form = new FormData(event.target);
    const data = {
      id: title,
      genres: genres.map((obj) => JSON.stringify(obj)),
      themes: themes.map((obj) => JSON.stringify(obj)),
      styles: styles.map((obj) => JSON.stringify(obj)),
      targetLength: targetLength,
      creativity: creativity,
      title: title,
      summary: summary,
      outline: outline,
      content: storyBase,
    };

    const existingBook = books.find((book) => book.id === data.id || book.title === data.title);

    if (existingBook) {
      // Update the existing book
      await API.graphql({
        query: updateBookMutation,
        variables: {
          input: {
            id: existingBook.id,
            ...data,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
    } else {
      await API.graphql({
        query: createBookMutation,
        variables: { input: data },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    }
    fetchBooks();
  }

  async function deleteBook({ id }) {
    const newBooks = books.filter((Book) => Book.id !== id);
    setBooks(newBooks);
    await API.graphql({
      query: deleteBookMutation,
      variables: { input: { id } },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
  }

  const loadBook = (book) => {
    console.log(book)
    if (book.genres.length > 0) {
      setGenres(book.genres.map((obj) => JSON.parse(obj)));
    }
    if (book.themes.length > 0) {
      setThemes(book.themes.map((obj) => JSON.parse(obj)));
    }
    if (book.styles.length > 0) {
      setStyles(book.styles.map((obj) => JSON.parse(obj)));
    }
    setTargetLength(book.targetLength);
    setCreativity(book.creativity);
    setTitle(book.title);
    setSummary(book.summary);
    setOutline(book.outline);
    setStorybase(book.content);
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function handleGenerateSummary() {
    setIsLoading(true);
    API.get(
      'generator',
      `/generator?genres=${genres.join(", ")}&themes=${themes.join(", ")}&styles=${styles.join(", ")}&creativity=${creativity}`
    )
    .then((response) => {
      console.log("response: ", response);
      setIsLoading(false);
      setSummary(response);
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false);
    });
  }

  const handleGenerateTitle = () => {
    setIsLoading(true);
    sleep(2000).then(() => {
      setIsLoading(false);
    });
    // TODO: implement function to generate title
  };

  const handleGenerateOutline = () => {
    setIsLoading(true);
    sleep(2000).then(() => {
      setIsLoading(false);
    });
    // TODO: implement function to generate outline
  };

  const handleGenerateStoryBase = () => {
    setIsLoading(true);
    sleep(2000).then(() => {
      setIsLoading(false);
    });
    // TODO: implement function to generate story base
  };

  const handleGrow = () => {
    setIsLoading(true);
    sleep(2000).then(() => {
      setIsLoading(false);
    });
    // TODO: implement function to grow story
  };

  // ----------------- GENRE ----------------- //

  const GENRES = ["Action", "Adventure", "Anthology", "Art", "Autobiography", "Biography", "Business", "Economics", "Children's", "Comics and Graphic Novels", "Computing and Technology", "Cookbooks", "Crime and Detective", "Drama", "Education", "Encyclopedias", "Engineering", "Erotic", "Espionage", "Family and Relationships", "Fantasy", "Guide", "Health and Fitness", "Historical Fiction", "History", "Horror", "Humor", "Journal", "LGBT", "Literary Fiction", "LitRPG", "Mathematics", "Medical", "Memoir", "Music", "Mystery", "Nature", "Paranormal", "Philosophy", "Poetry", "Political", "Psychology", "Religion", "Romance", "Science", "Science Fiction", "Self-help", "Short Stories", "Social Sciences", "Sports and Recreation", "Suspense and Thriller", "Travel", "True Crime", "War", "Westerns"];

  const genreSuggestions = GENRES.map(genre => {
    return {
      id: genre,
      text: genre
    };
  });

  const handleGenreDelete = i => {
    setGenres(genres.filter((genre, index) => index !== i));
  };

  const handleGenreAddition = genre => {
    if (genres.length >= 3) {
      return;
    }
    setGenres([...genres, genre]);
  };

  const handleGenreDrag = (genre, currPos, newPos) => {
    const newGenres = genres.slice();

    newGenres.splice(currPos, 1);
    newGenres.splice(newPos, 0, genre);

    // re-render
    setGenres(newGenres);
  };

  const handleGenreClick = index => {
    // console.log('The genre at index ' + index + ' was clicked');
  };

  // ----------------- THEME ----------------- //

  const THEMES = ["Coming of Age", "Courage and Heroism", "Death and Grief", "Dystopian Society", "Friendship", "Good vs. Evil", "Identity", "Love and Romance", "Mortality and Immortality", "Nature and the Environment", "Overcoming Adversity", "Prejudice and Discrimination", "Redemption and Forgiveness", "Revenge and Retribution", "Sacrifice", "Survival", "Transformation and Change", "War and Conflict", "Wisdom and Knowledge"];

  const themeSuggestions = THEMES.map(theme => {
    return {
      id: theme,
      text: theme
    };
  });

  const handleThemeDelete = i => {
    setThemes(themes.filter((theme, index) => index !== i));
  };

  const handleThemeAddition = theme => {
    if (themes.length >= 3) {
      return;
    }
    setThemes([...themes, theme]);
  };

  const handleThemeDrag = (theme, currPos, newPos) => {
    const newThemes = themes.slice();

    newThemes.splice(currPos, 1);
    newThemes.splice(newPos, 0, theme);

    // re-render
    setThemes(newThemes);
  };

  const handleThemeClick = index => {
    // console.log('The theme at index ' + index + ' was clicked');
  };

  // ----------------- STYLE ----------------- //

  const STYLES = ["Classic", "Experimental", "Fictionalized Memoir", "Flash Fiction", "Graphic Novel", "Historical Fiction", "Interactive Fiction", "Metafiction", "Microfiction", "Non-fiction Novel", "Novel in Verse", "Parody", "Postmodernist", "Realistic Fiction", "Romanticism", "Satire", "Science Fiction", "Short Story Collection", "Stream of Consciousness", "Surrealism", "Tragicomedy"];

  const styleSuggestions = STYLES.map(style => {
    return {
      id: style,
      text: style
    };
  });

  const handleStyleAddition = style => {
    if (styles.length >= 3) {
      return;
    }
    setStyles([...styles, style]);
  };

  const handleStyleDelete = i => {
    setThemes(styles.filter((style, index) => index !== i));
  };

  const handleStyleDrag = (style, currPos, newPos) => {
    const newStyles = styles.slice();

    newStyles.splice(currPos, 1);
    newStyles.splice(newPos, 0, style);

    // re-render
    setStyles(newStyles);
  };

  const handleStyleClick = index => {
    // console.log('The style at index ' + index + ' was clicked');
  };

  return (
    <View className="App"> 
      <Card>
        {/* <Image src={logo} className="App-logo" alt="logo" /> */}
      </Card>
      {/* <Button onClick={signOut}>Sign Out</Button> */}
      <div className="input-container">
        <label htmlFor="genre">Genre:</label>
        <ReactTags
          tags={genres}
          suggestions={genreSuggestions}
          delimiters={delimiters}
          handleDelete={handleGenreDelete}
          handleAddition={handleGenreAddition}
          handleDrag={handleGenreDrag}
          inputFieldPosition="inline"
          autocomplete
          maxLength={20}
          minQueryLength={1}
        />
      </div>
      <div className="input-container">
        <label htmlFor="themes">Themes:</label>
        <ReactTags
          tags={themes}
          suggestions={themeSuggestions}
          delimiters={delimiters}
          handleDelete={handleThemeDelete}
          handleAddition={handleThemeAddition}
          handleDrag={handleThemeDrag}
          inputFieldPosition="inline"
          autocomplete
          maxLength={20}
          minQueryLength={1}
        />
      </div>
      <div className="input-container">
        <label htmlFor="style">Styles:</label>
        <ReactTags
          tags={styles}
          suggestions={styleSuggestions}
          delimiters={delimiters}
          handleDelete={handleStyleDelete}
          handleAddition={handleStyleAddition}
          handleDrag={handleStyleDrag}
          inputFieldPosition="inline"
          autocomplete
          maxLength={20}
          minQueryLength={1}
        />
      </div>
        <div className="input-container">
        <label htmlFor="targetLength">Target Length in Words:</label>
        <input
          type="range"
          id="targetLength"
          min={10000}
          max={100000}
          value={targetLength}
          onChange={(e) => setTargetLength(parseInt(e.target.value))}
        />
        <span>{targetLength} words</span>
      
      </div>
      <div className="input-container">
        <label htmlFor="creativity">Creativity:</label>
        <input
          type="range"
          id="creativity"
          min={1}
          max={100}
          value={creativity}
          onChange={(e) => setCreativity(parseInt(e.target.value))}
        />
        <span>{creativity}%</span>
      </div>
      <button
        disabled={isLoading}
        className={`button ${isLoading ? 'button-disabled' : ''}`}
        onClick={handleGenerateSummary}>
        {isLoading ? <span className="loading-indicator" /> : 'Generate Summary'}
      </button>
      <button type="submit" variation="primary" onClick={createBook}>
        Save book
        {!isDirty && <span> ✅</span>}
      </button>
      <textarea value={summary} onChange={(e) => setSummary(e.target.value)} />
      <button
        disabled={isLoading}
        className={`button ${isLoading ? 'button-disabled' : ''}`}
        onClick={handleGenerateTitle}>
        {isLoading ? <span className="loading-indicator" /> : 'Generate Title'}
      </button>
      <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
      <button
        disabled={isLoading}
        className={`button ${isLoading ? 'button-disabled' : ''}`}
        onClick={handleGenerateOutline}>
        {isLoading ? <span className="loading-indicator" /> : 'Generate Outline'}
      </button>
      <textarea value={outline} onChange={(e) => setOutline(e.target.value)} />
      <button
        disabled={isLoading}
        className={`button ${isLoading ? 'button-disabled' : ''}`}
        onClick={handleGenerateStoryBase}>
        {isLoading ? <span className="loading-indicator" /> : 'Generate Story Base'}
      </button>
      <textarea value={storyBase} onChange={(e) => setStorybase(e.target.value)} />
      <button
        disabled={isLoading}
        className={`button ${isLoading ? 'button-disabled' : ''}`}
        onClick={handleGrow}>
        {isLoading ? <span className="loading-indicator" /> : 'Develop Story'}
      </button>
      <button type="submit" variation="primary" onClick={createBook}>
        Save book
      </button>
      <Heading level={2}>Your Books</Heading>
      <BookList books={books} deleteBook={deleteBook} loadBook={loadBook} />
      {/* <View margin="3rem 0">
        {books.map((book) => (
          <Flex
            key={book.id || book.title}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Text as="strong" fontWeight={700}>
              {book.title}
            </Text>
            <Text as="span">{book.summary}</Text>
            <Button variation="link" onClick={() => deleteBook(book)}>
              Delete book
            </Button>
            <Button variation="link" onClick={() => loadBook(book)}>
              Load book
            </Button>
          </Flex>
        ))}
      </View> */}
      <Button onClick={signOut}>Sign Out</Button>
    </View>
  );
}

export default withAuthenticator(App);
